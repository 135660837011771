<template>
    <div>
        <v-title title="Agents Artwork Grades"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Agents Artwork Check Grades</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <artwork-check-grades></artwork-check-grades>
        </div>
    </div>
</template>

<script>
import ArtworkCheckGrades from '@/components/elements/artwork/ArtworkCheckGrades'
export default {
    name: 'AgentsArtworkGrades',
    components: { ArtworkCheckGrades },
}
</script>
