<template>
    <mercur-checkbox v-model="model" :value="params.grade" :class="`checkbox-${params.grade.toLowerCase()}`"></mercur-checkbox>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'GradeCheckbox',
    computed: {
        model: {
            get () {
                return this.params.value ? this.params.value : []
            },
            set () {
                this.emit()
            },
        },
    },
    methods: {
        emit () {
            this.params.setValue(this.params.node, this.model)
        },
    },
})
</script>

<style lang="scss" scoped>
    $items: ('intermediate': #00AEEF, 'advanced': #EC008C, 'expert': #FFCE00, 'master': #000000, 'specialist': #56DA0F);
    @each $name, $color in $items {
        .checkbox-#{$name} {
            margin: 0 !important;
        }
        .checkbox-#{$name} {
            /deep/ .checkbox:checked + label:before {
                background-color: $color !important;
                border-color: $color !important;
            }
        }
    }
</style>
