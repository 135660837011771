<template>
    <div>
        <template  v-if="hasPermission('MerchantCentral/listAccounts')">
            <div class="mt-3">
                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                <merchant-data-table class="shadow" style="height: auto; min-height: auto;" :options="options" :url="url" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
            </div>
        </template>
        <p v-else>Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import GradeCheckbox from '@/components/elements/artwork/GradeCheckbox'

function generateCheckboxCells () {
    const grades = CONFIG.ARTWORK_GRADES
    const generatedCellsData = {}
    grades.forEach((grade) => {
        generatedCellsData[grade] = {
            field: 'accountSettings.artworkGrades',
            cellRendererFramework: GradeCheckbox,
            cellRendererParams: () => {
                return {
                    grade,
                    setValue: (node, grades) => {
                        node.setDataValue('accountSettings.artworkGrades', grades)
                        node.gridApi.redrawRows()
                    },
                }
            },
        }
    })
    return generatedCellsData
}

export default {
    name: 'ArtworkCheckGrades',
    components: { MerchantDataTable, Pagination },
    data () {
        return {
            pagination: {},
            amountOfDisplayedItems: 10,
            options: {
                columns: {
                    'Agent': {
                        field: ['firstName', 'lastName'],
                        sortable: true,
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    ...generateCheckboxCells(),
                },
                actions: {
                    items: [
                        {
                            icon: 'fas fa-save',
                            tooltip: 'Update agent',
                            onClick: ({ data }) => {
                                this.saveAgent(data)
                            },
                            isHidden: () => {
                                return !this.hasPermission('MerchantCentral/updateAccount')
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 10,
                cacheBlockSize: 10,
                defaultColDef: { menuTabs: ['filterMenuTab'] },
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.AGENTS.OVERVIEW
        },
    },
    methods: {
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        saveAgent (data) {
            const url = CONFIG.API.ROUTES.AGENTS.UPDATE.replace('{agentId}', data.accountId)
            this.post(url, data, 'Agent was updated')
        },
    },
}
</script>

<style lang="scss" scoped>
    h2 {
        font-weight: normal;
        display: flex;
        align-items: center;

        .c-default-table__pagination {
            flex: 1;
            margin-bottom: 0;
        }

        /deep/ .c-default-table__pagination-info {
            font-size: 14px;
        }
    }
</style>
